<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-12">
          <!-- v-if="popupParam.psiInterLockId" -->
          <c-card title="개정" class="cardClassDetailForm" topClass="topcolor-orange" >
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div class="row">
                  <div class="col-2">
                    <c-text
                      :required="editable && isRevision"
                      :editable="editable && isRevision"
                      label="개정번호"
                      name="revisionNum"
                      v-model="usePlan.revisionNum">
                    </c-text>
                  </div>
                  <div class="col-2">
                    <c-text
                      :editable="false"
                      label="개정일시"
                      name="regDtStr"
                      v-model="usePlan.regDtStr">
                    </c-text>
                  </div>
                  <div class="col-2">
                    <c-text
                      :editable="false"
                      label="개정자"
                      name="regUserName"
                      v-model="usePlan.regUserName">
                    </c-text>
                  </div>
                  <div class="col-6">
                    <c-text
                      :required="editable && isRevision"
                      :editable="editable && isRevision"
                      label="개정사유"
                      name="revisionContent"
                      v-model="usePlan.revisionContent">
                    </c-text>
                  </div>
                </div>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="공사현장 정보" class="cardClassDetailForm">
            <template slot="card-select">
              <c-select
                :editable="!isRevision"
                v-if="popupParam.smfUsePlanId"
                :comboItems="gridrev.data"
                type="custom"
                typetxt="개정이력"
                itemText="revisionNum"
                itemValue="smfUsePlanId"
                name="selectedSmfUsePlanId"
                label=""
                v-model="selectedSmfUsePlanId"
                @input="rowClickRev"
              ></c-select>
            </template>
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn
                  v-show="editable && !isRevision && !isHistory && isOld"
                  label="개정"
                  icon="restart_alt"
                  @btnClicked="setRevision" />
                <c-btn
                  v-show="editable && isRevision && !isHistory"
                  label="개정취소"
                  icon="restart_alt"
                  @btnClicked="cancelRevision" />
                <c-btn
                  v-show="editable && !isRevision && !disabled && !isHistory && isOld"
                  :url="permitUrl"
                  :isSubmit="isSubmit"
                  :param="usePlan"
                  mappingType="PUT"
                  label="제출"
                  icon="check"
                  @beforeAction="permitData"
                  @btnCallback="permitCallback" />
                <c-btn
                  v-show="editable && !disabled && !isHistory"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="usePlan"
                  :mappingType="mappingType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
                <c-plant 
                  :disabled="true"
                  :editable="editable"
                  name="plantCd" 
                  v-model="usePlan.plantCd" />
              </div>
              <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
                <c-text
                  :disabled="true"
                  :editable="editable"
                  type="number"
                  label="안전보건관리비 예산(원)"
                  name="projectSafetyCost"
                  v-model="usePlan.projectSafetyCost">
                </c-text>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
                <c-text
                  :disabled="true"
                  :editable="editable"
                  label="공사기간"
                  name="projectPeriod"
                  v-model="usePlan.projectPeriod"
                />
              </div>
              <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                <c-vendor-multi
                  :disabled="disabled"
                  :editable="editable"
                  label="사용할 업체(복수)"
                  name="smfVendorCds"
                  v-model="usePlan.smfVendorCds"
                />
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-12">
          <c-table
            ref="table"
            title="항목별 사용계획"
            tableId="usePlanItem"
            :columns="grid.columns"
            :data="usePlan.itemResult"
            gridHeight="500px"
            :editable="editable&&!disabled&&!isHistory"
            :columnSetting="false"
            :expandAll="false"
            :isFullScreen="false"
            :usePaging="false"
            :hideBottom="true"
            :filtering="false"
            @table-data-change="tableDataChange"
          >
          
            <template v-slot:suffixTitle>
              <font v-if="checkAmountTobeUsed" color="#C10015">
                ※ {{lackAmountTobeUsedText}}
              </font>
            </template>
          </c-table>
        </div>
      </div>
    </q-form>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'use-plan-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        smfUsePlanId: '',
        plantCd: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      selectedSmfUsePlanId: '',
      usePlan: {
        companyCd: '',
        smfVendorCds: '',
        smfUsePlanId: '',  // 안전보건관리비 사용계획 번호
        plantCd: '',  // 공사현장코드
        smfUsePlanStepCd: '',  // 제출진행상태
        projectSafetyCost: 0,
        projectPeriod: '',
        sysRevision: 1,  // 시스템 개정번호
        groupId: '',  // 일련번호 키
        revisionNum: '',  // 개정번호
        revisionContent: '제정',  // 개정사유
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        itemResult: [],
        revs: [],
      },
      grid: {
        columns: [
          {
            name: 'smfItemName',
            field: 'smfItemName',
            label: '항목',
            align: 'left',
            style: 'width: 400px',
            sortable: false,
          },
          {
            name: 'amountTobeUsed',
            field: 'amountTobeUsed',
            label: '사용계획금액(원)',
            align: 'right',
            style: 'width: 150px',
            sortable: false,
            type: 'cost'
          },
          {
            name: 'rate',
            field: 'rate',
            label: '비율(%)',
            style: 'width: 80px',
            align: 'right',
            sortable: false,
            type: 'number'
          },
          {
            name: 'remark',
            field: 'remark',
            label: '상세 사용계획 내용',
            style: 'width: 200px',
            align: 'left',
            sortable: false,
            type: 'textarea'
          },
          {
            name: 'attach',
            field: 'attach',
            label: '항목별 첨부',
            align: 'center',
            style: 'width: 250px',
            type: 'attach',
            taskClassCd: 'SMF_USE_PLAN_ITEM',
            keyText: 'smfUsePlanItemResultId',
            sortable: false,
          },
        ],
      },
      gridrev: {
        columns: [
          {
            name: 'revisionNum',
            field: 'revisionNum',
            label: '개정번호',
            align: 'center',
            style: 'width: 80px',
            sortable: true,
          },
          {
            name: 'revisionContent',
            field: 'revisionContent',
            label: '개정사유',
            align: 'left',
            sortable: true,
          },
          {
            name: 'regDtStr',
            field: 'regDtStr',
            label: '개정일시',
            style: 'width: 150px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'regUserName',
            field: 'regUserName',
            label: '개정자',
            align: 'center',
            style: 'width: 80px',
            sortable: true,
          },
        ],
        data: [],
      },
      mappingType: 'POST',
      detailUrl: '',
      saveUrl: '',
      permitUrl: '',
      editable: true,
      isSave: false,
      isSubmit: false,
      isRevision: false,
      realKey: '',
      getRevUrl: '',
      revlistUrl: '',
    };
  },
  computed: {
    isOld() {
      return Boolean(this.popupParam.smfUsePlanId)
    },
    isHistory() {
      return this.isOld && this.popupParam.smfUsePlanId !== this.usePlan.smfUsePlanId
    },
    disabled() {
      return this.isOld && this.usePlan.smfUsePlanStepCd === 'SUP0000005' && !this.isRevision
    },
    checkAmountTobeUsed() {
      return this.usePlan.projectSafetyCost !== this.$_.sum(this.$_.map(this.usePlan.itemResult, 'amountTobeUsed'))
    },
    lackAmountTobeUsedText() {
      let interval = this.usePlan.projectSafetyCost - this.$_.sum(this.$_.map(this.usePlan.itemResult, 'amountTobeUsed'))
      return '안전보건관리비 총 예산 ' + this.cuToThousandFilter(this.usePlan.projectSafetyCost) + ' 보다 ' + this.cuToThousandFilter(interval) +
        (interval > 0 ? ' 부족합니다.' : ' 많습니다.')
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.smf.usePlan.get.url
      this.revlistUrl = selectConfig.smf.usePlan.revs.url
      this.getRevUrl = selectConfig.smf.usePlan.rev.url
      this.saveUrl = transactionConfig.smf.usePlan.insert.url
      this.permitUrl = transactionConfig.smf.usePlan.update.url
      this.getDetail(this.popupParam.smfUsePlanId, true);
    },
    getDetail(_smfUsePlanId, _check) {
      if (_check) {
        this.isRevision = false;
        this.editable = this.$route.meta.editable;

        this.selectedSmfUsePlanId = _smfUsePlanId;
        this.$http.url = this.detailUrl
        this.$http.type = 'GET';
        this.$http.param = {
          smfUsePlanId: _smfUsePlanId,
          plantCd: this.popupParam.plantCd,
        }
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.usePlan, _result.data)

          if (_smfUsePlanId) {
            this.realKey = this.$_.cloneDeep(_result.data.smfUsePlanId)
            this.getRevList(_result.data.groupId);
          }
        },);
      } else {
        console.log(_smfUsePlanId)
        this.selectedSmfUsePlanId = _smfUsePlanId;
        this.$http.url = this.getRevUrl
        this.$http.type = 'GET';
          this.$http.param = {
            smfUsePlanId: _smfUsePlanId,
            plantCd: this.popupParam.plantCd,
          }
        this.$http.request((_result) => {
          if (_result.data) {
            this.$_.extend(this.usePlan, _result.data)
            if (_result.data.smfUsePlanId === this.realKey) {
              this.editable = this.$route.meta.editable;
            } else {
              this.editable = false;
            }
          }
        },);
      }
    },
    getRevList(_groupId) {
      this.$http.url = this.revlistUrl;
      this.$http.type = 'GET';
      this.$http.param = {groupId: _groupId};
      this.$http.request((_result) => {
        this.gridrev.data = _result.data;
      },);
    },
    tableDataChange(props, col) {
      if (col.name === 'rate') {
        if (props.row['rate'] && props.row['rate'] > 0) {
          props.row['amountTobeUsed'] = this.usePlan.projectSafetyCost * (props.row['rate'] / 100)
        } else {
          props.row['amountTobeUsed'] = 0
        }
      }
    },
    setRevision() {
      this.$http.url = selectConfig.com.revision.getversion;
      this.$http.param = {
        revisionNum: this.usePlan.revisionNum
      }
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.usePlan.revisionNum = _result.data;
        // this.usePlan.smfUsePlanStepCd = 'SUP0000001'
        this.usePlan.revisionContent = '';
        this.usePlan.regDtStr = '';
        this.usePlan.regUserName = '';
        this.$_.map(this.usePlan.itemResult, item => {
          item.smfUsePlanItemResultId = uid();
          return { ...item }
        })
        this.isRevision = true;
      });
    },
    rowClickRev(row) {
      if (row) {
        this.selectedSmfUsePlanId = row;
        this.getDetail(this.selectedSmfUsePlanId, false);
      }
    },
    cancelRevision() {
      this.getDetail(this.popupParam.smfUsePlanId ,true);
    },
    saveData() {
      if (this.popupParam.smfUsePlanId) {
        this.saveUrl = transactionConfig.smf.usePlan.update.url
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.smf.usePlan.insert.url
        this.mappingType = 'POST';
        this.usePlan.smfUsePlanStepCd = 'SUP0000001'
        this.usePlan.companyCd = this.$store.getters.user.companyCd;
      }
      let saveMsg = '저장하시겠습니까?';
      if (this.isRevision) {
        saveMsg = '현재버전을 개정하여 저장하시겠습니까?';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          if (this.checkAmountTobeUsed) {
            window.getApp.$emit('ALERT', {
              title: '안내', // 안내
              message: '비율을 100%로 맞춰주세요.', // 비율을 100%로 맞춰주세요.
              type: 'warning', // success / info / warning / error
            });
            return;
          }
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: saveMsg,
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.usePlan.regUserId = this.$store.getters.user.userId;
              this.usePlan.chgUserId = this.$store.getters.user.userId;
              // 개정시
              if (this.isRevision) {
                this.saveUrl = transactionConfig.smf.usePlan.insert.url
                this.mappingType = 'POST';
                this.usePlan.sysRevision = parseInt(this.usePlan.sysRevision) + 1;
                this.usePlan.smfUsePlanStepCd = 'SUP0000001'
              }
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.smfUsePlanId = result.data
      this.getDetail(this.popupParam.smfUsePlanId, true);
    },
    permitData() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          if (this.checkAmountTobeUsed) {
            window.getApp.$emit('ALERT', {
              title: '안내', // 안내
              message: '비율을 100%로 맞춰주세요.', // 비율을 100%로 맞춰주세요.
              type: 'warning', // success / info / warning / error
            });
            return;
          }
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '제출하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.usePlan.chgUserId = this.$store.getters.user.userId;
              this.usePlan.smfUsePlanStepCd = 'SUP0000005'
              this.isSubmit = !this.isSubmit;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    permitCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.smfUsePlanId = result.data
      this.getDetail(this.popupParam.smfUsePlanId, true);
    },
    cuToThousandFilter(num) {
      if (isNaN(num)) {
        return 0;
      } else {
        let _num = (+num || 0).toString().replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','))
        return _num == 0 ? num : _num
      }
    }
  }
};
</script>
